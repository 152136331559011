<template>
  <div id="selectRegisterCategory">
    <div class="container">
      <div class="row justify-content-center vh-100">
        <div class="col-lg-8 col-md-8 col-12 align-self-center">
          <div class="card">
            <div class="card-body login-card-body p-0 m-0">
              <div class="row justify-content-center">
                <div class="col-xl-3 col-lg-3 col-md-4 col-12 pt-5 pb-3 px-5">
                  <div class="logoBox text-center">
                    <img
                      class="h35"
                      src="../../assets/img/logo.png"
                      style="height: 100px"
                    />
                  </div>
                </div>
              </div>
              <div class="p-3">
                <div class="row text-center mb-2">
                  <div class="col-sm-12">
                    <h5 class="text-black-75">Select your category</h5>
                  </div>
                </div>

                <div class="row selectRegisterCategory">
                  <div class="col-sm-6">
                    <div
                      class="card text-center"
                      @click="fnSelectCategory('individualUser')"
                    >
                      <div class="card-body">
                        <div class="fs-4000">
                          <i class="fas fa-user-check"></i>
                        </div>
                        <h4 class="card-text fw-500">Individual user</h4>
                        <p class="fs-1000 text-black-50">
                          I am an individual requiring a background check for
                          myself.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div
                      class="card text-center"
                      @click="fnSelectCategory('corporateUsers')"
                    >
                      <div class="card-body">
                        <div class="fs-4000">
                          <i class="fas fa-building fs-3000"></i>
                        </div>
                        <h4 class="card-text fw-500">Corporate user</h4>
                        <p class="fs-1000 text-black-50">
                          My company requires background checks on employees.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SelectRegisterCategory",
  components: {},
  data() {
    return {};
  },
  methods: {
    fnSelectCategory(step) {
      if (step === "individualUser") {
        this.$router.push("/select-register-individual");
      }
      if (step === "corporateUsers") {
        this.$router.push("/select-register-corporate");
      }
    },
  },
};
</script>
